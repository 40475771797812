<template>
  <div style="background:#fff">
    <div class="cont">
      <!-- 机构 -->
      <section class="organization">
        <p v-for="item in data.institutionList">
          <span>机构</span>
          <span>{{ item.name }}</span>
        </p>
        <p v-for="item in data.countryList">
          <span>国别公司</span>
          <span>{{ item.name }}</span>
        </p>
        <p v-for="item in data.projectList">
          <span>项目</span>
          <span>{{ item.name }}</span>
        </p>
      </section>

      <!-- 数据板 -->

      <ul class="databoardNumber">
        <!-- 1 -->
        <!-- <li>
          <div>
            <h5>{{ data.workUser.length }}</h5>
            <p>在岗人数</p>
          </div>
          <div>
            <h5>{{ data.returnUser.length }}  <span style="font-size:12px;color:red" v-if="data.returnIso lateUser.length">隔离{{data.returnIsolateUser.length}}</span></h5>
            <p>回国人数 </p>
          </div>
          <div>
            <h5>{{ data.outIsolateUser.length }}</h5>
            <p>境外隔离</p>
          </div>
        </li> -->
        <!-- 2 -->
        <!-- <li>
          <div>
            <h5>{{ data.ownUser.length }}</h5>
            <p>自有人数</p>
          </div>
          <div>
            <h5>{{ data.ownReturnUser.length }}</h5>
            <p>自有回国人员</p>
          </div>
          <div>
            <h5>{{ data.ownInIsolateUser.length }}</h5>
            <p>境内隔离</p>
          </div>
          <div>
            <h5>{{ data.ownOutIsolateUser.length }}</h5>
            <p>境外隔离</p>
          </div>
        </li> -->
        <!-- 3 -->
        <!-- <li>
          <div>
            <h5>{{ data.externalUser.length }}</h5>
            <p>外聘人员</p>
          </div>
          <div>
            <h5>{{ data.externalReturnUser.length }}</h5>
            <p>外聘回国人员</p>
          </div>
          <div>
            <h5>{{ data.externalInIsolateUser.length }}</h5>
            <p>境内隔离</p>
          </div>
          <div>
            <h5>{{ data.externalOutIsolateUser.length }}</h5>
            <p>境外隔离</p>
          </div>
        </li> -->
        <!-- 4 -->
        <!-- <li>
          <div>
            <h5>{{ data.serviceUser.length }}</h5>
            <p>务工人员</p>
          </div>
          <div>
            <h5>{{ data.serviceReturnUser.length }}</h5>
            <p>务工回国人员</p>
          </div>
          <div>
            <h5>{{ data.serviceInIsolateUser.length }}</h5>
            <p>境内隔离</p>
          </div>
          <div>
            <h5>{{ data.serviceOutIsolateUser.length }}</h5>
            <p>境外隔离</p>
          </div>
        </li> -->
      </ul>
    </div>
    <!-- 总人数 -->
    <section class="total">
      <h1>{{ data.totalList.length }}</h1>
      <p>总人数</p>
    </section>
    <section style="padding:0 10px">
      <div class="part">
      <!-- <h1>境外人员 {{ data.outList&&data.outList.length }}</h1> -->
      <div class="content">
        <div class="left">
          <h5>{{ data.ownList && data.ownList.length }}</h5>
          <p>自有人员</p>
        </div>
        <div class="right">
          <div class="number">
            <span>{{
              data.ownIsolateMedicalList && data.ownIsolateMedicalList.length
            }}</span>
            <p>医学隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.ownIsolateHomeList && data.ownIsolateHomeList.length
            }}</span>
            <p>居家隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.ownIsolateCenterList && data.ownIsolateCenterList.length
            }}</span>
            <p>集中隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.ownIsolateWatchList && data.ownIsolateWatchList.length
            }}</span>
            <p>健康观察</p>
          </div>
          <div class="number color1">
            <span>{{
              data.ownIsolateSubCloseList && data.ownIsolateSubCloseList.length
            }}</span>
            <p>次密切接触</p>
          </div>
          <div class="number color2">
            <span>{{
              data.ownIsolateCloseList && data.ownIsolateCloseList.length
            }}</span>
            <p>密切接触者</p>
          </div>
          <div class="number color2">
            <span>{{
              data.ownPositiveList && data.ownPositiveList.length
            }}</span>
            <p>核酸阳性</p>
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <!-- <h1>境外人员 {{ data.outList&&data.outList.length }}</h1> -->
      <div class="content">
        <div class="left">
          <h5>{{ data.externalList && data.externalList.length }}</h5>
          <p>管理人员</p>
        </div>
        <div class="right">
          <div class="number">
            <span>{{
              data.externalIsolateMedicalList &&
              data.externalIsolateMedicalList.length
            }}</span>
            <p>医学隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.externalIsolateHomeList &&
              data.externalIsolateHomeList.length
            }}</span>
            <p>居家隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.externalIsolateCenterList &&
              data.externalIsolateCenterList.length
            }}</span>
            <p>集中隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.externalIsolateWatchList &&
              data.externalIsolateWatchList.length
            }}</span>
            <p>健康观察</p>
          </div>
          <div class="number color1">
            <span>{{
              data.externalIsolateSubCloseList &&
              data.externalIsolateSubCloseList.length
            }}</span>
            <p>次密切接触</p>
          </div>
          <div class="number color2">
            <span>{{
              data.externalIsolateCloseList &&
              data.externalIsolateCloseList.length
            }}</span>
            <p>密切接触者</p>
          </div>
          <div class="number color2">
            <span>{{
              data.externalPositiveList && data.externalPositiveList.length
            }}</span>
            <p>核酸阳性</p>
          </div>
        </div>
      </div>
    </div>
    <div class="part">
      <!-- <h1>境外人员 {{ data.outList&&data.outList.length }}</h1> -->
      <div class="content">
        <div class="left">
          <h5>{{ data.serviceList && data.serviceList.length }}</h5>
          <p>劳务人员</p>
        </div>
        <div class="right">
          <div class="number">
            <span>{{
              data.externalIsolateMedicalList &&
              data.externalIsolateMedicalList.length
            }}</span>
            <p>医学隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.serviceIsolateHomeList && data.serviceIsolateHomeList.length
            }}</span>
            <p>居家隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.externalIsolateCenterList &&
              data.externalIsolateCenterList.length
            }}</span>
            <p>集中隔离</p>
          </div>
          <div class="number">
            <span>{{
              data.externalIsolateWatchList &&
              data.externalIsolateWatchList.length
            }}</span>
            <p>健康观察</p>
          </div>
          <div class="number color1">
            <span>{{
              data.externalIsolateSubCloseList &&
              data.externalIsolateSubCloseList.length
            }}</span>
            <p>次密切接触</p>
          </div>
          <div class="number color2">
            <span>{{
              data.externalIsolateCloseList &&
              data.externalIsolateCloseList.length
            }}</span>
            <p>密切接触者</p>
          </div>
          <div class="number color2">
            <span>{{
              data.externalPositiveList && data.externalPositiveList.length
            }}</span>
            <p>核酸阳性</p>
          </div>
        </div>
      </div>
    </div>
    </section>
    <!-- 疫情数据 -->
    <div class="databoard">
      <div>
        <h5>{{ data.epidemicInfo.updateNum }}</h5>
        <p>今日确诊</p>
      </div>
      <div>
        <h5>{{ data.epidemicInfo.nowNum }}</h5>
        <p>现存确诊</p>
      </div>
      <div>
        <h5>{{ data.epidemicInfo.totalNum }}</h5>
        <p>累计确诊</p>
      </div>
      <div>
        <h5>{{ data.epidemicInfo.curedNum }}</h5>
        <p>累计治愈</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountryDetail } from "@/api/api";
export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    let resident = this.$route.query.resident;
    document.title = resident;
    this.getData({ resident: resident });
  },
  methods: {
    async getData(query) {
      let res = await getCountryDetail(query);
      this.data = res.data.data;
    },
  },
};
</script>

<style scoped>
.cont {
  background: #2f7dcd;
  padding: 0.2rem 0.17rem;
}
.organization p {
  font-size: 0.18rem;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0.14rem 0;
}
.organization p span:first-child {
  color: rgba(255, 255, 255, 0.7);
}
.organization p:not(:last-child) {
  border-bottom: 1px dotted #fff;
}
.total {
  text-align: center;
  color: #121212;
  font-size: 0.16rem;
  font-weight: lighter;
}
.total h1 {
  font-weight: normal;
  font-size: 0.34rem;
}
.databoardNumber {
  list-style: none;
}
.databoardNumber li {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 0.08rem 0;
  border-radius: 14px;
  padding: 0.18rem;
  padding-top: 0.1rem;
  text-align: center;
  color: #666666;
}
.databoardNumber li h5 {
  font-weight: normal;
  font-size: 0.24rem;
}

.databoardNumber li p {
  font-size: 0.13rem;
}
.databoardNumber li div:nth-child(3) {
  color: #e10000;
}
.databoardNumber li div:nth-child(4) {
  color: #e10000;
}

.databoard {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 0.1rem 0;
  border-radius: 5px;
}
.databoard > div {
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
}
.databoard > div > h5 {
  font-size: 0.2rem;
}
.databoard > div > p {
  font-size: 0.12rem;
  background: #f6f7fd;
  display: inline-block;
  line-height: 0.2rem;
  padding: 0 0.1rem;
  border-radius: 0.1rem;
}
.databoard > div:first-child h5 {
  color: #4861c7;
}
.databoard > div:nth-child(2) h5 {
  color: #cf2b1e;
}
.databoard > div:nth-child(3) h5 {
  color: #4ba185;
}
.databoard > div:nth-child(4) h5 {
  color: #051a3c;
}

.part {
  background: rgba(59, 115, 198, 0.05);
  
  border-radius: 10px;
  margin:0.2rem 0;
  margin-bottom: 0.15rem;
}

/* .part:first-child h1 {
  background: rgba(97, 133, 178, 0.24);
}
.part:first-child .content .left {
  color: #666;
} */
.content {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.content .left {
  color: #ec5d78;
  text-align: center;
  flex: 1;
  border-right: 1px solid #707070;
}
.content .right {
  flex: 2.9;
  padding: 0 0.1rem;
}
.content .left h5 {
  font-weight: normal;
  font-size: 0.35rem;
  line-height: 1.3;
}
.content .left p {
  font-size: 0.12rem;
}
.content .right {
  display: flex;
  /* justify-content: space-around; */
  flex-wrap: wrap;
}
.content .right .number {
  text-align: center;
  color: #666;
  width: 25%;
  margin: 0.05rem 0;
}
.content .right .number span {
  font-size: 0.24rem;
}
.content .right .number p {
  font-size: 0.12rem;
}
.color1 span {
  color: #f7b51b;
}
.color2 span {
  color: #ec5d78;
}
.tableBox {
  padding: 0.1rem;
}
.tableBox h5 {
  color: #999999;
  font-size: 0.16rem;
  margin-bottom: 0.1rem;
}
.table {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.table li {
  display: flex;
  justify-content: space-between;
  font-size: 0.13rem;
  color: #707071;
  line-height: 0.35rem;
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
}
.table li span {
  flex: 1;
  text-align: center;
}
.table li.thead {
  background: rgba(79, 119, 170, 0.24);
  line-height: 0.4rem;
}

/* new  */
.header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}
.header .left {
  text-align: left;
}
.header .left h1 {
  font-size: 0.35rem;
  line-height: 1.2;
  font-weight: normal;
}
.header .left p {
  font-weight: lighter;
  font-size: 0.12rem;
}
.header .right {
  font-size: 0.16rem;
  font-weight: normal;
}
.header .right span {
  margin-right: 5px;
}
.row {
  display: flex;
  justify-content: space-between;
  list-style: none;
  color: #fff;
  margin: 0.15rem 0 0.3rem;
  text-align: center;
}
.row h5 {
  font-size: 0.32rem;
  font-weight: normal;
  line-height: 1.2;
}
.row p {
  font-size: 0.12rem;
  font-weight: lighter;
}
.row li {
  flex: 1;
}
.row li:first-child {
  border-right: 1px dotted #fff;
  text-align: left;
  flex: 0.9;
}
.part h1 {
  font-weight: bold;
  font-size: 0.15rem;
  line-height: 0.4rem;
  padding: 0 0.15rem;
  background: #f5f5f5;
  color: #333;
}
.part-content {
  background: #fff;
  padding-bottom: 0.15rem;
}
.part-content .content {
  background: rgba(59, 115, 198, 0.05);
  margin: 0.15rem;
  border-radius: 0.1rem;
}
</style>